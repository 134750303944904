export class StatusConstants {
  static shop = {
    all: { value: '', label: 'すべて' },
    waiting: { value: 0, label: '審査待ち' },
    rejected: { value: 1, label: '否決' },
    hold: { value: 2, label: '保留' },
    approved: { value: 3, label: '承認済み' },
    // canceled: { value: 4, label: '解約' },
    pause: { value: 5, label: '一時停止' },
    preparation: { value: 6, label: '準備中' },
  };
  static judge = {
    approved: { value: 3, label: '可決', class: 'selectBtn-btn-green' },
    rejected: { value: 1, label: '否決', class: 'selectBtn-btn-red' },
    hold: { value: 2, label: '保留', class: 'selectBtn-btn-yellow' },
  };
  static role = {
    gmo: { value: '9', label: 'master' },
    office: { value: '1', label: 'client' },
    store: { value: '3', label: 'shop' },
  };
  static topic = {
    office: 1,
    shop: 2,
  };
  static interval = {
    weekly: { value: 'weekly', label: '週次', totalLabel: '週次合計' },
    monthly: { value: 'monthly', label: '月次', totalLabel: '月次合計' },
    yearly: { value: 'yearly', label: '年次', totalLabel: '年次合計' },
  };
  static bank = {
    usually: { value: 1, label: '普通' },
    current: { value: 2, label: '当座' },
  };
  static payment = {
    own: {
      1: '支払い済み',
      0: 'キャンセル',
    },
    manual: {
      shop: '店舗消費',
      office: '事務局消費',
    },
  };
  static gender = {
    male: '男性',
    female: '女性',
    other: 'その他',
  };
  static currency = {
    parent: {
      charge: {
        manual: { value: 1, label: '事務局発行' },
        company: { value: 3, label: '企業発行' },
        store: { value: 2, label: '店舗発行' },
      },
      payment: {
        manual: { value: 1, label: '事務局消費' },
        store: { value: 2, label: '店舗消費' },
        cancel: { value: 3, label: '取り消しポイント' },
      },
    },
    child: {
      charge: {
        problem: { value: 3, label: '発行' },
        store: { value: 2, label: '店舗発行' },
      },
      payment: {
        store: { value: 2, label: '店舗消費' },
        cancel: { value: 6, label: '取り消しポイント' },
      },
    }
  };
  static manualPoint = {
    charge: {
      manual: { value: 1, label: '事務局発行' },
      store: { value: 2, label: '店舗発行' },
    },
    payment: {
      manual: { value: 1, label: '事務局消費' },
      store: { value: 2, label: '店舗消費' },
      cancelPoint: { value: 3, label: '取り消しポイント' },
      cancelPointStore: { value: 4, label: '店舗取り消しポイント' },
    },
  };
  static currencyType = {
    charge: 'charge',
    payment: 'payment',
  };
  static chargeType = {
    creditcard: { value: '1'},
    convenienceStore: { value: '2'},
    payeasy: { value: '3'},
    maruPay: { value: '4' },
  };
  static history = {
    charge: {
      manual: { value: 'manual', label: '手動発行' },
      own: { value: 'own', label: '会員本人によるチャージ' },
      shop: { value: 'shop', label: '店舗発行' },
      issuer: { value: 'issuer', label: '企業発行' }
    },
    payment: {
      manual: { value: 'manual', label: '手動利用' },
      own: { value: 'own', label: '会員本人による支払い' },
      cancel: { value: 'cancel', label: '取り消し' },
      expired: { value: 'expired', label: '有効期限切れ' },
      shop: { value: 'shop', label: '手動消費' },
      issuerCancel: { value: 'issuerCancel', label: '企業消費' },
    },
  };
  static chargeStatus = {
    waiting: '入金待ち',
    done: '入金',
    cancel: 'キャンセル',
    confirm: '認証待ち',
    fail: '認証失敗',
  };
  static exportCsvStatus = {
    waiting: 0,
    success: 1,
    cancel: 9,
  };
  static pointType = {
    all: { value: 1, label: '全イベント共通', class: 'mr-50' },
    special: { value: 2, label: '子イベント単独' },
  };
  static customerType = {
    all: { value: 'all', label: 'すべての顧客' },
    normal: { value: 'normal', label: '一般顧客' },
    cpm: { value: 'cpm', label: 'CPM顧客' },
    omise: { value: 'omise', label: 'モバイル会員' },
    identity: { value: 'identity', label: '本人確認済' },
    sms: { value: 'sms', label: 'SMS認証済' },
  };
  static userCpmStatus = {
    active: { value: 1, label: 'アクティベート済' },
    inactive: { value: 0, label: '非アクティベート' },
  };
  static chargeDateType = {
    buy: { value: 1, label: '購入日' },
    cancel: { value: 2, label: 'キャンセル日' },
    ticketPayment: { value: 5, label: 'キャンセル' },
  };
  static paymentDateType = {
    payment: { value: 1, label: '支払日' },
    front: { value: 2, label: 'キャンセル日' },
    manual: { value: 3, label: '取り消し日' },
    expired: { value: 4, label: '有効期限切れ日' },
  };
  static volumeStatus = [
    { value: 100, label: '100%' },
    { value: 90, label: '90%' },
    { value: 80, label: '80%' },
    { value: 70, label: '70%' },
    { value: 60, label: '60%' },
    { value: 50, label: '50%' },
    { value: 40, label: '40%' },
    { value: 30, label: '30%' },
    { value: 20, label: '20%' },
    { value: 10, label: '10%' },
  ];
  static pointStatus = {
    addedIntendPoint : { value: 11, label: '加算予定' }
  };
  static userRoles = [
    { value: 9, label: 'master（運営アカウント）'},
    { value: 1.1, label: 'client 1（運営アカウント（親））'},
    { value: 1.2, label: 'client 2（運営アカウント（子））'},
    { value: 3, label: 'shop（加盟店アカウント）'},
    { value: 4, label: 'group（店舗グループアカウント）'},
  ];
  static templateUseDate = {
    unlimit: { label: '期限なし', value: 0 },
    limit: { label: '期間限定', value: 1 },
  };
  static pushModal = {
    notification: 'notificationModal',
    coupon: 'couponModal',
    news: 'newsModal',
  };
  static giftCardType = {
    white: { value: 0, label: '白色', class: 'mr-50' },
    black: { value: 1, label: '黒色' },
  };
  static otpReleaseStatus = [
    { value: 0, label: '有効期限切れ' },
    { value: 1, label: '有効' },
  ];
  static otpStatus = [
    { value: 0, label: '未使用' },
    { value: 1, label: '使用済' },
    { value: 2, label: '無効化' },
  ];
  static soundType = {
    web: { value: 1, label: 'デジタルPayのWebで鳴らす' },
    app: { value: 2, label: 'アプリで鳴らす' },
    nec: { value: 3, label: '環境に応じて鳴らす' },
  };
  static identityType = {
    xId: 1,
    civilios: 2,
    digital: 3,
  };
}
