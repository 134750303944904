import { helpers } from 'vuelidate/lib/validators';
import { compareAsc } from 'date-fns';
import { CommonConstants } from '@/constants/common';
import { stripTags } from '@/helpers/formatData';

export const onlyKana = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.KATAKANA_VALIDATION_REGEX.test(value);
};

export const isPhoneNumber = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.PHONE_VALIDATION_REGEX.test(value);
};

export const isZipcode = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.ZIPCODE_REGEX.test(value);
};

export const isAlphanumSpecial = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.ALPHANUMSPECIAL_VALIDATION_REGEX.test(value);
};

export const isAlphanumAndSpecial = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.ALPHANUM_AND_SPECIAL_VALIDATION_REGEX.test(value);
};

export const isFacebookUrl = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.FACEBOOK_URL_REGEX.test(value);
};

export const isTwitterUrl = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.TWITTER_URL_REGEX.test(value);
};

export const isInstagramUrl = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.INSTAGRAM_URL_REGEX.test(value);
};

export const isLineUrl = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.LINE_URL_REGEX.test(value);
};

export const isUrl = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.URL_REGEX.test(value);
};

export const isBankCode = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.BANK_CODE_REGEX.test(value);
};

export const isBranchCode = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.BRANCH_CODE_REGEX.test(value);
};

export const isBankNumber = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.BANK_NUMBER_REGEX.test(value);
};

export const isBankName = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.BANK_NAME_REGEX.test(value);
};

export const isNumberLowercaseDash = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.NUMBER_LOWERCASE_DASH_VALIDATION_REGEX.test(value);
};

export const notConsecutiveDashes = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.NOT_CONSECUTIVE_DASHES_VALIDATION_REGEX.test(value);
};

export const notSpace = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return !CommonConstants.SPACE_REGEX.test(value);
};

export const isDuplicateField = (list, field) => {
  if (!list || !field) {
    return true;
  }
  const valueArray = list.map((item) => item[field]).filter((item) => item);
  return new Set(valueArray).size === valueArray.length;
}

export const isAlphanumSpecialWithMinLength = (minLength) =>
  helpers.withParams(
    { type: 'alphanumSpecialWithMinLength', min: minLength },
    (value) => {
      if (typeof value === 'undefined' || value === null || value === '') {
        return true;
      }
      return CommonConstants.ALPHANUMSPECIAL_VALIDATION_REGEX.test(value) && value.length >= minLength;
    }
  )

export const isAlphanumSpecialWithMinMaxLength = (minLength, maxLength) =>
  helpers.withParams(
    { type: 'alphanumSpecialWithMinMaxLength', min: minLength, max: maxLength },
    (value) => {
      if (typeof value === 'undefined' || value === null || value === '') {
        return true;
      }
      return CommonConstants.ALPHANUMSPECIAL_VALIDATION_REGEX.test(value) && value.length >= minLength && value.length <= maxLength;
    }
  )

export const isAlphanumAndSpecialWithMinMaxLength = (minLength, maxLength) =>
  helpers.withParams(
    { type: 'alphanumAndSpecialWithMinMaxLength', min: minLength, max: maxLength },
    (value) => {
      if (typeof value === 'undefined' || value === null || value === '') {
        return true;
      }
      return CommonConstants.ALPHANUM_AND_SPECIAL_VALIDATION_REGEX.test(value) && value.length >= minLength && value.length <= maxLength;
    }
  )

export const isAlphaHiraganaWithMinMaxLength = (minLength, maxLength) =>
  helpers.withParams(
    { type: 'alphaHiraganaWithMinMaxLength', min: minLength, max: maxLength },
    (value) => {
      if (typeof value === 'undefined' || value === null || value === '') {
        return true;
      }
      const isHiragana = Array.from(value).every((char) => CommonConstants.ALPHABET_HIRAGANA_VALIDATION_REGEX.test(char));
      return isHiragana && value.length >= minLength && value.length <= maxLength;
    }
  )

export const isAlphanumWithMaxLength = (maxLength) =>
  helpers.withParams(
    { type: 'alphanumWithMaxLength', max: maxLength },
    (value) => {
      if (typeof value === 'undefined' || value === null || value === '') {
        return true;
      }
      return CommonConstants.ALPHABET_NUMERIC_VALIDATION_REGEX.test(value) && value.length <= maxLength;
    }
  )

export const stripTagsMaxLength = (maxLength) =>
  helpers.withParams(
    { type: 'maxLength', max: maxLength },
    (value) => {
      if (typeof value === 'undefined' || value === null || value === '') {
        return true;
      }
      return stripTags(value).length <= maxLength;
    }
  )

export const isNowBetweenTwoDates = (fromDate, toDate) => {
  if (!fromDate || !toDate) {
    return true;
  }
  const from = new Date(fromDate);
  const to = new Date(toDate);
  from.setHours(0, 0, 0);
  to.setHours(23, 59, 59);
  const now = new Date();
  return (compareAsc(to, now) !== -1) && (compareAsc(now, from) !== -1)
};

export const lessValue = (greaterValue, setErrorField) => {
  if (typeof greaterValue === 'undefined' || greaterValue === null || greaterValue === '') {
    return true;
  } else {
    return helpers.withParams(
      { type: 'lessValue', notLabelInFirst: true},
      (value) => {
        if (typeof value === 'undefined' || value === null || value === '') {
          return true;
        }
        const result = value <= Number.parseInt(greaterValue);
        if (!result && setErrorField) {
          setErrorField();
        }
        return result;
      }
    )
  }
}

export const isDateWithMinMaxValue = (minValue, maxValue, notLabelInFirst = true) =>
  helpers.withParams(
    { type: 'isDateWithMinMaxValue', min: minValue, max: maxValue, notLabelInFirst: notLabelInFirst },
    (value) => {
      if (typeof value === 'undefined' || value === null || value === '') {
        return true;
      }
      return value >= minValue && value <= maxValue;
    }
  )

export const isDateWithSameAsValue = (startDate, endDate) =>
  helpers.withParams(
    { type: 'isDateWithSameAsValue', notLabelInFirst: true },
    () => {
      if (!startDate || !endDate) {
        return true;
      }
      return startDate !== endDate;
    }
  )

export const isPercentWithMinMaxValue = (minValue, maxValue) =>
  helpers.withParams(
    { type: 'isPercentWithMinMaxValue', min: minValue, max: maxValue, notLabelInFirst: true },
    (value) => {
      if (!value) {
        return true;
      }
      return value >= 0.1 && value <= 999.9;
    }
  )

export const isDecimal = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return CommonConstants.DECIMAL_VALIDATION_REGEX.test(value);
};

export const isNotPeriodPoint = () =>
  helpers.withParams(
    { type: 'notPeriodPoint', notLabelInFirst: true },
    () => {
      return false;
    }
  )

export const isExpireDaysWithMinValue = (minValue) =>
  helpers.withParams(
    { type: 'validMailAfterDaysCharge', notLabelInFirst: true },
    (value) => {
      return value >= minValue;
    }
  )

export const isCharWithMinMaxLength = (minLength, maxLength) =>
  helpers.withParams(
    { type: 'characterWithMinMaxLength', min: minLength, max: maxLength },
    (value) => {
      if (typeof value === 'undefined' || value === null || value === '') {
        return true;
      }
      return value.length >= minLength && value.length <= maxLength
    }
  )

export const isMultipleNumber = (value, minValue) => {
  if (!Number(minValue)) return true;
  return (Number(value) % Number(minValue)) === 0;
}

export const isLimitWithValidValue = (value, startValue, endValue) => {
  if (typeof startValue === 'undefined' || startValue === null || startValue === '') {
    return true;
  }
  return !(Number(startValue) < Number(value) * Number(endValue));
}

export const isLimitAndUnitWithValidValue = (value, item) => {
  if (typeof item?.budgetAmount === 'undefined' || item?.budgetAmount === null || item?.budgetAmount === '') {
    return true;
  }
  return !(Number(item?.budgetAmount) < ((Number(value) / Number(item?.unitBreakdown))  * Number(item?.purchaseAmount)));
}

export const isUnique = (group, key) => {
  return (value) => {
    const found = group.filter(item => {
      if (key) {
        return item[key] == value;
      }
      return item == value;
    });
    return found.length <= 1;
  };
}

export const hasValueOrNewUpload = (value, newUpload) => {
  return newUpload ? true : !!Number(value);
}

export const isBetweenWithNoLabel = (minValue, maxValue) => helpers.withParams(
  { type: 'oneTimeTokenBetween', min: minValue, max: maxValue, notLabelInFirst: true },
  (value) => {
    if (!value) return true;
    return Number(minValue) <= Number(value) && Number(value) <= Number(maxValue);
  }
)

export const isNumberAndAlphabetSpecialHalfsize = () =>
  helpers.withParams(
    { type: 'numberAndAlphabetSpecialHalfsize'},
    (value) => {
      if (typeof value === 'undefined' || value === null || value === '') {
        return true;
      }
      return CommonConstants.ALPHANUM_SPECIAL_VALIDATION_REGEX.test(value)
    }
  )

export const isPointWithMinMaxValue = (minValue, maxValue) =>
  helpers.withParams(
    { type: 'isPointWithMinMaxValue', min: minValue, max: maxValue, notLabelInFirst: true },
    (value) => {
      if (!value) {
        return true;
      }
      return value >= minValue && value <= maxValue;
    }
  )
